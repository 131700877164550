<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>money-bag-dollar</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M13.574,12.75H11.541a1.342,1.342,0,0,0-.5,2.587l2.064.826a1.342,1.342,0,0,1-.5,2.587H10.574"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.074"
      y1="12.75"
      x2="12.074"
      y2="12"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.074"
      y1="19.5"
      x2="12.074"
      y2="18.75"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="6.824"
      y1="8.25"
      x2="17.324"
      y2="8.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.63,5.036,17.068,2.16a.75.75,0,0,0-1.006-1.006l-1.9.948a.75.75,0,0,1-.959-.255L12.7,1.084a.75.75,0,0,0-1.248,0l-.509.763a.749.749,0,0,1-.959.255L8.087,1.154A.751.751,0,0,0,7.08,2.16L8.49,4.98"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M7.237,10.5l-2.76,3.2a5.932,5.932,0,0,0,4.7,9.549h5.79a5.932,5.932,0,0,0,4.7-9.549l-2.76-3.2"
    />
  </svg>
</template>
